@import "../../scss/index.scss";

.landingPage {
    width: 100vw;
    height: 100vh;
    background-color: #303F9F;
    user-select: none;
    .menu{
        background-color: #303F9F;
        position: fixed;
        z-index: 5;
        width: 100vw;
        ul {
            list-style-type: none;
            margin: 0;
            padding: 0;
            overflow: hidden;
            display: flex;
            justify-content: flex-end;
            margin-right: 50px;
            margin-bottom: 5px;
        }
          
        li {
            float: left;
        }
          
        li a {
            display: block;
            color: white;
            text-align: center;
            padding: 14px 16px;
            text-decoration: none;
            font-weight: bolder;
            font-size: x-large;
            border-bottom: 5px solid transparent;
        }
          
          /* Change the link color to #111 (black) on hover */
        li a:hover {
            border-bottom-color:  #FFC107;
            color: white;
            cursor: pointer;
        }
    }
    .presentationFlexContainer{
        display: flex;
        width: 100vw;
        height: 100vh;
        .leftSide{
            width: 40vw;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: center;
            img{
                width: 200px;
                height: 200px;
            }
            p{
                font-size: 80px;
                color: white;
                font-family: 'Solway';
                line-height: 1;
            }
            button{
                margin-top: 50px;
                margin-bottom: 150px;
                background-color: #FFC107;
                height: 60px;
                width: 240px;
                color: #303F9F;
                font-weight: bolder;
                font-size: large;
            }
        }
        .rightSide{
            flex-direction: column;
            width: 60vw;
              
            img{
                height: 80vh;
                margin-top: 60px
            }
        }
    }
    .descriptionContainer{
        width: 100vw;
        height: 100vh;
        background-color: #f1f1ee;
        position:relative;
        #fig1 {
            position: absolute;
            left: 50px;
            top: 100px;
            z-index: 1;
        }
        #fig2 {
            position: absolute;
            right: 5vw;
            top: 60%;
        }
        h1{
            color: #303F9F;
            font-family: 'Solway';
            position: relative;
            top: 150px;
        }
        p{
            position: relative;
            top: 200px;
            margin-right: 300px;
            margin-left: 300px;
            color: #757575;
            font-weight: bold;
        }
    }
    .caracteristicasContainer{
        width: 100vw;
        height: 100vh;
        display: flex;
        flex-direction: column;
        background-color: #f1f1ee;
        h1{
            color: #303F9F;
            font-family: 'Solway';
            position: relative;
            top: 150px;
        }
        .columnsContainer{
            display: flex;
            height: 100%;
            position: relative;
            .descColumn{
                flex-grow: 1;
                height: 100%;
                flex-direction: column;
                align-items: center;
                width: 25vw;
                padding: 5rem;
                img{
                    margin-top: 180px;
                }
                h1{
                    color: #303F9F;
                    font-family: 'Solway';
                    position: relative;
                    top: -1px;
                    font-size: x-large;
                }
                p{
                    color: #303F9F;
                    font-family: 'Solway';
                    font-weight: lighter;
                }
            }
        }
    }

    .contactoContainer{
        width: 100vw;
        height: 100vh;
        display: flex;
        background-color: #f1f1ee;
        .column{
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            align-items: center;
            color: #192679;
            font-family: 'Solway';
            img{
                margin-top: 250px;
                width: 300px;
                height: 300px;
            }
            h1{
                margin-top: 250px;
            }
            p{
                color: #212121;
            }
        }
    }
}

.menu-item-selected {
    border-bottom-color:  #FFC107;
}
.menu-item-unselected {
    border-bottom: 5px solid transparent;
}
