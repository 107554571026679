@import "../../scss/index.scss";


.modal-custom{
    .modal-content{
        background-color: $color-primary-dark;
        color: white;
        padding: 10px;
        border: 0px;
        border-radius: 2%;
        .modal-header{
            border-bottom: 1px solid $color-divider;
        }
        .row{
            margin-bottom: 35px;
            margin-top: 35px;
        }
    }
}

@media (min-width: 800px){
    
    .modal-dialog {
        max-width: 800px !important;
        margin: 1.75rem auto;
    }
}