.main-component {
    .grid-layout{
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(250px, 250px));
        grid-gap: 15px 15px;
        justify-content: space-evenly;
    }
    .col-10{
        position: relative;
        left: -60px;
    }
}