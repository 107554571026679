@import "../../scss/index.scss";

.categoria-page{
    .grid-layout{
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(230px, 230px));
        grid-gap: 15px 15px;
        justify-content: space-evenly;
    }
    .col-10{
        position: relative;
        left: -60px;
    }
}

.modal-content{
    .palabra-card{
        &__palabra{
            h1{
                text-align: center;
                font-size: 60px;
                font-weight: 900;
            }
        }
        &__significado{
            margin-top: 30px;
            h3{
                display: inline;
                margin-right: 30px;
                font-size: 30px;
                font-weight: 100;
            }
        }
        &__ejemplos{
            margin-top: 60px;
        }
    }


}