@import "../../scss/index.scss";

.completar-juego{
    p{
        color: white;
        font-weight: 900;
    }
    border-radius: 2%;
    background-color: $color-juego;
    width: calc(100vw - 240px);
    height: calc(100vh - 80px);
    margin: 40px;
    border-color: black;
    &__header{
        position: relative;
        display: flex;
        border-top-left-radius: 2%;
        border-top-right-radius: 2%;
        width: 100%;
        height: 20vh;
        background-color: $color-juego-dark;
        h1{
            color: white;
            position: absolute;
            left: 10vw;
            top: 5vh;
        }
    }
    &__body{
        position: relative;
        width: 100%;
        height: 50vh;
        padding: 20px;
        h1{
           color: white; 
        }
    }
    &__footer{
        padding: 20px;
        .row{
            margin: 0px;
        }
    }   
}