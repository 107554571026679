@import "../../scss/index.scss";
.categoria-main-component{
    svg{
        position: relative;
        z-index: 1;
        top: 10px;
        left: 230px;
        fill: white;
        width: 25px;
        height: 25px;
        &:hover{
            cursor: pointer;
        }
    }
    .categoria-component{
        position: relative;
        display: inline-block;
        width: 250px;
        height: 150px;
        border-radius: 10%;

        p{
            position: relative;
            top:50px;
            color: white;
            font-weight: 900;
        }

        &:hover{
            cursor: pointer;
            box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
            text-decoration: none;
        }
    }
}




.palabra-component{
    width: 230px;
    height: 300px;
    background-color:hsl(0, 0%, 95%);
    border-radius: 15px;
    box-shadow: 1px 1px 5px 2px hsl(0, 0%, 80%);
    display: inline-block;
    cursor: pointer;

    .elementos{
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;

        .imagen {
            width: 200px;
            height: 170px;
            box-shadow: inset 0px 0px 5px 0px hsl(0, 0%, 80%);
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
        }
        img{
            width: 200px;
            height: 170px;
        }
        
        .nombre {
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: center;
            text-align: center;
            font-size: xx-large;
        }
        
        .fa-ellipsis-v {
            margin: 10px;
            margin-right: 30px;
        }
        
        .palabra-component > * :hover{
            cursor: pointer;
        }
        
        
        .icons {
            color: hsl(0, 0%, 60%);
        }
        
        /* Menú */
        #menu {
            align-self: flex-end;
        }
        
        #menu ul{
            list-style: none;
            margin: 0px;
            padding: 0px;
        }
        
        
        
        button{
            background-color: hsl(0, 0%, 95%);
            border-width: 0px;
        }
        
        
        
        #menu ul ul {
            display: none;
            top:100%;
            left:10px;
            background: hsl(0, 0%, 98%);
            padding:0px;
        }
        
        #menu ul button:focus > ul {
            display:block;
            position:absolute;
            border-style: solid;
            border-width: 1px;
            border-color: hsl(0, 0%, 80%);
        }
        
        /* cada elemento del menu*/
        nav li{
            display:inline-block;
            padding:0px;
            position:relative;
        }
        
        /* cada elemento del submenu */
        nav li li{
            padding: 10px;
            width: 80px;
        }
        
        nav li li:hover{
            padding: 10px;
            background-color: hsl(0, 0%, 80%);
        }
        
    }
}

