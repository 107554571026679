$color-primary: #3F51B5;
$color-primary-dark: #303F9F;
$color-primary-light: #C5CAE9;
$color-accent: #FFC107;
$color-accent-dark: hsl(45,70%,51%);
$color-text: #212121;
$color-text-secondary: #757575;
$color-divider: #BDBDBD;
$color-logo: #e6e6e6;
$color-juego: #2e7d32;
$color-juego-dark: #1b5e20;
$color-green: #1b5e20;
$color-red: #b71c1c;
$color-purple: #512da8;
$color-yellow: #FFC107;